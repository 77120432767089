// //Reset default browser styles
// @import './utils/reset';

// //Variables
// @import './utils/variables';

// //Functions
// @import './utils/functions';
// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// tailwind

// @import "tailwindcss/base";

// @import "tailwindcss/components";

// @import "tailwindcss/utilities";
@use "@angular/material" as mat;
@include mat.core();
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

/** Estilos scrollbar */
@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #fff;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 100vh;
  }
}

html {
  height: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  max-width: 100%;
  margin: 0px auto;
}

.min-height {
  min-height: 90vh;
}

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/static/OpenSans/OpenSans-Regular.ttf) format("truetype");
}

label {
  min-height: 25px;
}

/** Estilos paginacion */
body .ngx-pagination .current {
  background: none;
  color: black;
  font-weight: bold;
}

body .ngx-pagination .pagination-previous a {
  color: #86bc25;
}

body .ngx-pagination .pagination-next a {
  color: #86bc25;
}

/** Estilos sidebar */
.sidebar .section,
.sidebar .field_section {
  display: block;
  width: 100%;
}

.sidebar .section_title {
  font-size: 1.25rem;
  font-style: italic;
  color: #75787b;
  font-weight: bold;
}

.search_input {
  width: 100%;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25);
}

/** Estilos radiobutton */
input[type="radio"] {
  margin-right: 6px;
  position: relative;
  visibility: hidden;
}

input[type="radio"]:after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -2px;
  left: 0px;
  position: absolute;
  background-color: transparent;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid black;
}

input[type="radio"]:checked:after {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 0px;
  left: 2px;
  position: absolute;
  background-color: black;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #e5e5e5;
  outline: 2px solid black;
}

/** Estilos checkbox */
input[type="checkbox"] {
  margin-right: 9px;
  position: relative;
  visibility: hidden;
}

input[type="checkbox"]:after {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  top: -2px;
  left: 0px;
  position: absolute;
  visibility: visible;
  background-image: url("../svg/checkbox.svg");
}

input[type="checkbox"]:checked:after {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  top: -2px;
  left: 0px;
  position: absolute;
  visibility: visible;
  background-image: url("../svg/checkbox_checked.svg");
}

/* Accordion styles */
@media only screen and (max-width: 767px) {
  .tabs {
    border-radius: 8px;
    overflow: hidden;
  }
  .tab {
    width: 100%;
    overflow: hidden;
  }
  .tab-label {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    cursor: pointer;
  }
  .tab-label::after {
    content: "+";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.1s;
  }
  .tab-content {
    max-height: 0;
    transition: all 0.1s;
  }
  .tab-close {
    display: flex;
    justify-content: flex-end;
    font-size: 0.75em;
    cursor: pointer;
  }
  input:checked + .tab-label::after {
    content: "-";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.1s;
  }
  input:checked ~ .tab-content {
    max-height: max-content;
  }
}
/* tipografia del texto de los headers negros del home */
.home-card-header-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

/* tipografia del texto en negrita del campo titulo y descripcion de las tarjetas del home */
.title-description-text {
  max-height: 5rem;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.title-description-text > span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  word-break: break-word;
}

.card-regular-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: #000000;
}

.home-card-date-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;

  display: flex;
  align-items: center;

  color: #47494b;
}

.home-card-title-date-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #75787b;
}

.dot {
  height: 0.25rem;
  width: 0.25rem;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
}

.mat-snack-bar-container {
  display: contents !important;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.loader {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.mat-menu-content:not(:empty) {
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: -4px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.mat-menu-item {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-size: 0.875rem;
}

.mat-menu-item:hover {
  --tw-text-opacity: 1;
  color: #86bc25;
  font-weight: 700;
}
.three-dots {
  border-radius: 50%;
  cursor: pointer;
}
.title {
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  border-radius: 8px;
}
.horizontal-line {
  border: 0.5px solid #75787b;
  width: 100%;
  margin-top: 50px;
}
.horizontal-line-0 {
  border: 0.5px solid #75787b;
  width: 100%;
  margin-top: 0;
}
.horizontal-line-mini {
  border: 0.5px solid #75787b;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}
.input-form {
  padding: 20px 12px;
  width: 100%;
  height: 46px;
  background: #ffffff;
  border: 1px solid #75787b;
  box-sizing: border-box;
  border-radius: 4px;
}
.input-error-bottom {
  span {
    @extend .red !optional;

    font-size: 10px;
    line-height: 10px;
    display: block;
  }
}
.center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.space-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.space-around {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.justify-end {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.upload-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  cursor: pointer;
}

.mat-form-field {
  width: 100%;
}

.red {
  color: red;
}
.wrap {
  display: flex;
  flex-wrap: wrap;
}

.visibility-hidden {
  visibility: hidden;
  margin-top: 10px;
}
.visibility-visible {
  visibility: visible;
  margin-top: 10px;
}
.change-lang-active {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(134, 188, 37, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(134, 188, 37, var(--tw-border-opacity));
  border-width: 1px;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
.change-lang-disabled {
  --tw-text-opacity: 1;
  color: rgba(117, 120, 123, var(--tw-text-opacity));
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(117, 120, 123, var(--tw-border-opacity));
  border-width: 1px;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
.input-margin-top {
  margin-top: 30px;
  margin-bottom: 6px;
}
.responsive-width {
  max-width: 600px;
  width: 100%;
  position: relative;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
}

textarea {
  resize: none;
}

.margin {
  width: 25%;
  /* min-width: 100px; */
}

.align-center {
  display: flex;
  align-items: center;
}

.wrapper-modal {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
::ng-deep .tooltip-width {
  display: flex;
  flex-wrap: wrap;
  max-width: 50px;
}

::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  // outline: 1px solid slategrey;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.tag {
  background-color: #e4e5e5;
}